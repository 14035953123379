export const indicators = [
  {
    title: 'Всего задач',
    count: '139',
  },
  {
    title: 'Выполнено',
    count: '139',
  },
  {
    title: 'Показатель объема',
    count: '7%',
  },
];

export const quarters = [
  {
    title: '4 кв.',
    percent: 79,
  },
  {
    title: '3 кв.',
    percent: 32,
  },
  {
    title: '2 кв.',
    percent: 16,
  },
  {
    title: '1 кв.',
    percent: 16,
  },
];

export const tasks1 = {
  sort: 'Список задач',
  head: [
    'Детализированная задача',
    'Задач',
    'Выполнено',
    'Отклонение',
  ],
  tasks: [
    {
      name: 'Номер работы',
      count: 10,
      done: 1,
      deviation: -94,
    },
    {
      name: 'Номер работы2',
      count: 10,
      done: 10,
      deviation: 79,
    },
    {
      name: 'Номер работы3',
      count: 10,
      done: 8,
      deviation: 45,
    },
    {
      name: 'Номер работы4',
      count: 10,
      done: 3,
      deviation: -52,
    },
    {
      name: 'Номер работы5',
      count: 10,
      done: 9,
      deviation: 93,
    },
    {
      name: 'Номер работы6',
      count: 10,
      done: 4,
      deviation: -9,
    },
    {
      name: 'Номер работы7',
      count: 10,
      done: 3,
      deviation: -62,
    },
  ],
};

export const tasks2 = {
  sort: 'Ответственный исполнитель',
  head: [
    'Исполнитель',
    'Задач',
    'Выполнено',
    'Отклонение',
  ],
  tasks: [
    {
      name: 'Работа 1',
      count: 10,
      done: 1,
      deviation: -94,
    },
    {
      name: 'Работа 2',
      count: 10,
      done: 10,
      deviation: 79,
    },
    {
      name: 'Работа 3',
      count: 10,
      done: 8,
      deviation: 45,
    },
    {
      name: 'Работа 4',
      count: 10,
      done: 3,
      deviation: -52,
    },
    {
      name: 'Работа 5',
      count: 10,
      done: 9,
      deviation: 93,
    },
    {
      name: 'Работа 6',
      count: 10,
      done: 4,
      deviation: -9,
    },
    {
      name: 'Работа 7',
      count: 10,
      done: 3,
      deviation: -62,
    },
  ],
};

export const plans = [
  {
    title: '1 кв.',
    percent: 20,
  },
  {
    title: '2 кв.',
    percent: 35,
  },
  {
    title: '3 кв.',
    percent: 67,
  },
  {
    title: '4 кв.',
    percent: 91,
  },
];

export const circles = [
  {
    size: 65,
    text: '16/50%<br><span>ОРГАНИЗАЦИОННЫЙ<br>ОТДЕЛ</span>',
    bg: '#1083D0',
  },
  {
    size: 38,
    text: '12/30%',
    bg: '#13B1DE',
  },
  {
    size: 28,
    text: '12/18%',
    bg: '#317A52',
  },
  {
    size: 15,
    text: '16/15%',
    bg: '#C63E59',
  },
  {
    size: 31,
    text: '12/15%',
    bg: '#1E2F4D',
  },
  {
    size: 15,
    text: '16/10%',
    bg: '#FFFFFF',
    color: '#1E2F4D',
  },
  {
    size: 28,
    text: '16/10%',
    bg: '#317A52',
  },
  {
    size: 15,
    text: '16/10%',
    bg: '#D3A95F',
  },
]
