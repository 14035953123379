import AppStyles from './App.module.scss';
import Header from '../Header/Header';
import Group from '../Group/Group';
import Filter from '../Filter/Filter';
import Indicators from '../Indicators/Indicators';
import Quarters from '../Quarters/Quarters';
import Circles from '../Circles/Circles';
import Tasks from '../Tasks/Tasks';
import Plans from '../Plans/Plans';
import { indicators, quarters, tasks1, tasks2 } from '../../data';

function App() {
  return (
    <div className={ AppStyles.app }>
      <div className={ AppStyles.wrap }>
        <Header />
        <div>
          <Group size='s' topic='Основные показатели'>
            <Filter />
            <Indicators indicators={ indicators } />
            <Quarters quarters={ quarters } />
          </Group>
          <Group
            topic='Задачи'
            rows={[
              {
                title: 'Детализированные задачи ответственных исполнителей',
                element: <Tasks { ...tasks1 } />,
              },
              {
                title: 'Вклад структурных подразделений в реализацию ГЗ',
                element: <Circles />
              }
            ]}
          />
          <Group
            topic='Работы'
            rows={[
              {
                title: 'Количество задач, выполненных в разрезе работ',
                element: <Tasks { ...tasks2 } />,
              },
              {
                title: 'План-факт выполнения государственного задания',
                element: <Plans />,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
