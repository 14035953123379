import Sort from '../Sort/Sort';
import TasksStyles from './Tasks.module.scss';

function Tasks({
  sort,
  head,
  tasks,
} : {
  sort: string,
  head: string[],
  tasks: Array<{
    name: string,
    count: number,
    done: number,
    deviation: number,
  }>,
}) {
  return (
    <section className={ TasksStyles.tasks }>
      <Sort title={ sort } />
      <div className={ TasksStyles.head }>
        <div className={ TasksStyles.row }>
          {
            head.map((i, key) => (
              <div key={ key } className={` ${ TasksStyles.cell } ${ TasksStyles[`cell_mod_${ key + 1 }`] }`}>
                <p className={ TasksStyles.text }>{ i }</p>
              </div>
            ))
          }
        </div>
      </div>
      <div className={ TasksStyles.body }>
        {
          tasks.map((i, key) => (
            <div key={ key } className={ TasksStyles.row }>
              <div className={` ${ TasksStyles.cell } ${ TasksStyles.cell_mod_1 } `}>
                <p className={ TasksStyles.text }>{ i.name }</p>
              </div>
              <div className={` ${ TasksStyles.cell } ${ TasksStyles.cell_mod_2 } `}>
                <p className={ TasksStyles.text }>{ i.count }</p>
                <div className={ TasksStyles.percent } style={{ width: '100%' }} />
              </div>
              <div className={` ${ TasksStyles.cell } ${ TasksStyles.cell_mod_3 } `}>
                <p className={ TasksStyles.text }>{ i.done }</p>
                <div className={ TasksStyles.percent } style={{ width: `${ i.done * 100 / 10 }%` }} />
              </div>
              <div className={` ${ TasksStyles.cell } ${ TasksStyles.cell_mod_4 } `}>
                <p className={ TasksStyles.text }>{ i.deviation }%</p>
                <div className={ TasksStyles.part }>
                  {
                    i.deviation < 0 &&
                      <div className={ TasksStyles.percent } style={{ width: `${ Math.abs(i.deviation) }%` }} />
                  }
                </div>
                <div className={ TasksStyles.part }>
                  {
                    i.deviation > 0 &&
                      <div className={ TasksStyles.percent } style={{ width: `${ i.deviation }%` }} />
                  }
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </section>
  );
}

export default Tasks;
