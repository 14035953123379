import QuartersStyles from './Quarters.module.scss';

function Quarters({
  quarters
} : {
  quarters: Array<{
    title: string,
    percent: number,
  }>
}) {
  return (
    <div className={ QuartersStyles.quarters }>
      <h2 className={ QuartersStyles.topic }>Количество детализированых задач по&nbsp;кварталам</h2>
      <div className={ QuartersStyles.body }>
        {
          quarters.map((i, key) => (
            <div key={ key } className={ QuartersStyles.row }>
              <div className={ QuartersStyles.cell }>
                <p className={ QuartersStyles.text }>{ i.title }</p>
              </div>
              <div className={ QuartersStyles.cell }>
                <div className={ QuartersStyles.percent } style={{ width: `${ i.percent }%`}}></div>
              </div>
            </div>
          ))
        }
        <button className={ QuartersStyles.menu }>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="5" fill="none"><path fill="#fff" d="M22.048 4.128c-.683 0-1.184-.17-1.504-.512-.299-.341-.448-.79-.448-1.344v-.416c0-.555.15-1.003.448-1.344.32-.341.821-.512 1.504-.512s1.173.17 1.472.512c.32.341.48.79.48 1.344v.416c0 .555-.16 1.003-.48 1.344-.299.341-.79.512-1.472.512Zm-7.904 0c-.683 0-1.184-.17-1.504-.512-.299-.341-.448-.79-.448-1.344v-.416c0-.555.15-1.003.448-1.344.32-.341.82-.512 1.504-.512s1.173.17 1.472.512c.32.341.48.79.48 1.344v.416c0 .555-.16 1.003-.48 1.344-.299.341-.79.512-1.472.512Zm-7.904 0c-.683 0-1.184-.17-1.504-.512-.299-.341-.448-.79-.448-1.344v-.416c0-.555.15-1.003.448-1.344C5.056.171 5.557 0 6.24 0s1.173.17 1.472.512c.32.341.48.79.48 1.344v.416c0 .555-.16 1.003-.48 1.344-.299.341-.79.512-1.472.512Z"/></svg>
        </button>
      </div>
    </div>
  );
}

export default Quarters;
