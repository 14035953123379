import SortStyles from './Sort.module.scss';

function Sort({
  title,
} : {
  title: string,
}) {
  return (
    <div className={ SortStyles.sort }>
      <div className={ SortStyles.icon }>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="28" fill="none"><path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" d="M11.667 18.396 8 21.603l-3.667-3.207M4.333 9.604 8 6.396l3.667 3.208"/></svg>
      </div>
      <p className={ SortStyles.title }>{ title }</p>
    </div>
  );
}

export default Sort;
