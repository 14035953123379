import SelectStyles from './Select.module.scss';

function Select({
  label,
} : {
  label: string,
}) {
  return (
    <div className={ SelectStyles.select }>
      <div className={ SelectStyles.head }>
        <p className={ SelectStyles.label }>{ label }</p>
        <div className={ SelectStyles.icon }>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"><path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" d="M11.667 6.396 8 9.604 4.333 6.396"/></svg>
        </div>
      </div>
    </div>
  );
}

export default Select;
