import DateStyles from './Date.module.scss';

function Date({
  label,
} : {
  label: string,
}) {
  return (
    <div className={ DateStyles.date }>
      <p className={ DateStyles.text }>01.04.2024 - 31.05.2024</p>
    </div>
  );
}

export default Date;
