import { useRef, useState, useEffect } from 'react';
import Sort from '../Sort/Sort';
import CirclesStyles from './Circles.module.scss';
import { circles } from '../../data';

function Circles() {
  const refChart = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (!refChart.current) {
      return
    }

    setHeight(refChart.current.clientHeight);
  }, [refChart]);

  function renderItem({
    i,
    key,
  } : {
    key: number,
    i: {
      bg: string,
      size: number,
      color?: string,
      text: string,
    }
  }) {
    return (
      <div
        key={ key }
        className={ CirclesStyles.bubble }
        style={{
          color: i.color,
          backgroundColor: i.bg,
          width: `${ (i.size * height) / 100 }px`,
        }}
      >
        <p dangerouslySetInnerHTML={{ __html: i.text }} className={ CirclesStyles.text } />
      </div>
    )
  }

  return (
    <section className={ CirclesStyles.circles }>
      <div className={ CirclesStyles.body }>
        <div className={ CirclesStyles.legend }>
          <Sort title='Исполнитель' />
          <div className={ CirclesStyles.legendBody }>
            <div className={ CirclesStyles.legendRow }>
              <div className={ CirclesStyles.legendIcon } style={{ backgroundColor: '#1083D0' }} />
              <p className={ CirclesStyles.legendText }>Отдел 1</p>
              <p className={ CirclesStyles.legendPercent }>30%</p>
            </div>
            <div className={ CirclesStyles.legendRow }>
              <div className={ CirclesStyles.legendIcon } style={{ backgroundColor: '#13B1DE' }} />
              <p className={ CirclesStyles.legendText }>Отдел 2</p>
              <p className={ CirclesStyles.legendPercent }>10%</p>
            </div>
            <div className={ CirclesStyles.legendRow }>
              <div className={ CirclesStyles.legendIcon } style={{ backgroundColor: '#1E2F4D' }} />
              <p className={ CirclesStyles.legendText }>Отдел 3</p>
              <p className={ CirclesStyles.legendPercent }>10%</p>
            </div>
            <div className={ CirclesStyles.legendRow }>
              <div className={ CirclesStyles.legendIcon } style={{ backgroundColor: '#C63E59' }} />
              <p className={ CirclesStyles.legendText }>Отдел 4</p>
              <p className={ CirclesStyles.legendPercent }>10%</p>
            </div>
            <div className={ CirclesStyles.legendRow }>
              <div className={ CirclesStyles.legendIcon } style={{ backgroundColor: '#317A52' }} />
              <p className={ CirclesStyles.legendText }>Отдел 5</p>
              <p className={ CirclesStyles.legendPercent }>10%</p>
            </div>
            <div className={ CirclesStyles.legendRow }>
              <div className={ CirclesStyles.legendIcon } style={{ backgroundColor: '#D3A95F' }} />
              <p className={ CirclesStyles.legendText }>Отдел 6</p>
              <p className={ CirclesStyles.legendPercent }>10%</p>
            </div>
          </div>
        </div>
        <div ref={ refChart } className={ CirclesStyles.chart }>
        <div className={ CirclesStyles.column }>
          { circles.slice(0, 2).map((i, key) => renderItem({ key, i })) }
        </div>
        <div className={ CirclesStyles.column }>
          { circles.slice(2, 5).map((i, key) => renderItem({ key, i })) }
        </div>
        <div className={ CirclesStyles.column }>
          { circles.slice(5, 8).map((i, key) => renderItem({ key, i })) }
        </div>
        </div>
      </div>
    </section>
  );
}

export default Circles;
