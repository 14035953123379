import { useRef, useState, useEffect } from 'react';
import { plans } from '../../data';
import PlansStyles from './Plans.module.scss';

function Plans() {
  const refChart = useRef<HTMLDivElement>(null);
  const refBar = useRef<Array<HTMLDivElement | null>>([])
  const [lineWidth, setLineWidth] = useState<Array<number>>([]);
  const [lineAngle, setlineAngle] = useState<Array<number>>([]);

  useEffect(() => {
    const bars = refBar.current;
    const widths:number[] = [];
    const angles:number[] = [];

    bars.forEach((i, key) => {
      const left = i?.offsetLeft || 0;
      const top = i?.offsetTop || 0;
      const leftPrev = bars[key - 1]?.offsetLeft || 0;
      const topPrev = bars[key - 1]?.offsetTop || 0;

      const width = Math.sqrt(Math.pow(left - leftPrev, 2) + Math.pow(top - topPrev, 2));
      const angle = Math.atan(((top - topPrev) / (left - leftPrev))) * (180 / Math.PI) ;

      widths.push(width);
      angles.push(angle);
    });

    setLineWidth(widths);
    setlineAngle(angles);
  }, [refBar]);

  return (
    <div className={ PlansStyles.plans }>
      <div className={ PlansStyles.body }>
        <div className={ PlansStyles.grid }>
          <div className={ PlansStyles.row }>
            <p className={ PlansStyles.text }>30м</p>
          </div>
          <div className={ PlansStyles.row }>
            <p className={ PlansStyles.text }>25м</p>
          </div>
          <div className={ PlansStyles.row }>
            <p className={ PlansStyles.text }>20м</p>
          </div>
          <div className={ PlansStyles.row }>
            <p className={ PlansStyles.text }>15м</p>
          </div>
          <div className={ PlansStyles.row }>
            <p className={ PlansStyles.text }>10м</p>
          </div>
          <div className={ PlansStyles.row }>
            <p className={ PlansStyles.text }>5м</p>
          </div>
          <div className={ PlansStyles.row }>
            <p className={ PlansStyles.text }>0</p>
          </div>
        </div>
        <div ref={ refChart } className={ PlansStyles.chart }>
          {
            plans.map((i, key) => (
              <div
                key={ key }
                ref={ref => refBar.current[key] = ref}
                className={ PlansStyles.item }
                style={{ height: `${ i.percent }%` }}
              >
                <div className={ PlansStyles.bg }></div>
                <p className={ PlansStyles.text }>{ i.title }</p>
                <div className={ PlansStyles.line } style={{ width: lineWidth[key], transform: `rotate(${lineAngle[key]}deg)` }} />
              </div>
            ))
          }
        </div>
      </div>
      <div className={ PlansStyles.legend }>
        <div className={ PlansStyles.legendItem }>
          <div className={ PlansStyles.legendIcon } style={{ backgroundColor: '#16608C' }} />
          <p className={ PlansStyles.legendText }>Работа 1</p>
        </div>
        <div className={ PlansStyles.legendItem }>
          <div className={ PlansStyles.legendIcon } style={{ backgroundColor: '#14AED9' }} />
          <p className={ PlansStyles.legendText }>Работа 2</p>
        </div>
        <div className={ PlansStyles.legendItem }>
          <div className={ PlansStyles.legendIcon } style={{ backgroundColor: '#B8B8B9' }} />
          <p className={ PlansStyles.legendText }>Работа 3</p>
        </div>
        <div className={ PlansStyles.legendItem }>
          <div className={ PlansStyles.legendIcon } style={{ backgroundColor: '#FFFFFF' }} />
          <p className={ PlansStyles.legendText }>План</p>
        </div>
      </div>
    </div>
  );
}

export default Plans;
