import Date from '../Date/Date';
import Select from '../Select/Select';
import FilterStyles from './Filter.module.scss';

function Filter() {
  return (
    <div className={ FilterStyles.filter }>
      <Select label='Исполнитель' />
      <Select label='Номер работы' />
      <Select label='Отчетный период' />
      <Date label='01.04.2024 - 31.05.2024' />
    </div>
  );
}

export default Filter;
