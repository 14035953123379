import HeaderStyles from './Header.module.scss';
import logo from './i/logo.png';

function Header() {
  return (
    <header className={ HeaderStyles.header }>
      <div className={ HeaderStyles.body }>
        <a href='/' className={ HeaderStyles.logo }>
          <img src={ logo } alt='' />
        </a>
        <button className={ HeaderStyles.menu }>
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="10" fill="none"><path stroke="#fff" strokeLinecap="round" strokeWidth="1.5" d="M1 9h16M1 1h16"/></svg>
        </button>
      </div>
    </header>
  );
}

export default Header;
