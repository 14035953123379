import GroupStyles from './Group.module.scss';
// import logo from './i/logo.png';

function Group({
  size,
  rows,
  topic,
  children,
} : {
  size?: 's',
  rows?: Array<{
    title: string,
    element: JSX.Element,
  }>,
  topic: string,
  children?: JSX.Element | JSX.Element[],
}) {
  return (
    <section
      className={`
        ${ GroupStyles.group }
        ${ size ? GroupStyles.group_s_s : '' }
      `}
    >
      <h2 className={ GroupStyles.topic }>{ topic }</h2>
      {
        rows?.map((i, key) => (
          <div key={ key } className={ GroupStyles.row }>
            <div className={ GroupStyles.head }>
              <h3 className={ GroupStyles.title }>{ i.title }</h3>
              <button className={ GroupStyles.menu }>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="5" fill="none"><path fill="#fff" d="M22.048 4.128c-.683 0-1.184-.17-1.504-.512-.299-.341-.448-.79-.448-1.344v-.416c0-.555.15-1.003.448-1.344.32-.341.821-.512 1.504-.512s1.173.17 1.472.512c.32.341.48.79.48 1.344v.416c0 .555-.16 1.003-.48 1.344-.299.341-.79.512-1.472.512Zm-7.904 0c-.683 0-1.184-.17-1.504-.512-.299-.341-.448-.79-.448-1.344v-.416c0-.555.15-1.003.448-1.344.32-.341.82-.512 1.504-.512s1.173.17 1.472.512c.32.341.48.79.48 1.344v.416c0 .555-.16 1.003-.48 1.344-.299.341-.79.512-1.472.512Zm-7.904 0c-.683 0-1.184-.17-1.504-.512-.299-.341-.448-.79-.448-1.344v-.416c0-.555.15-1.003.448-1.344C5.056.171 5.557 0 6.24 0s1.173.17 1.472.512c.32.341.48.79.48 1.344v.416c0 .555-.16 1.003-.48 1.344-.299.341-.79.512-1.472.512Z"/></svg>
              </button>
            </div>
            { i.element }
          </div>
        ))
      }
      { children }
    </section>
  );
}

export default Group;
