import IndicatorsStyles from './Indicators.module.scss';

function Indicators({
  indicators
} : {
  indicators: Array<{
    title: string,
    count: string,
  }>
}) {
  return (
    <div className={ IndicatorsStyles.indicators }>
      <div className={ IndicatorsStyles.body }>
        {
          indicators.map((i, key) => (
            <div key={ key } className={ IndicatorsStyles.item }>
              <p className={ IndicatorsStyles.title }>{ i.title }</p>
              <p className={ IndicatorsStyles.count }>{ i.count }</p>
              <button className={ IndicatorsStyles.menu }>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="5" fill="none"><path fill="#fff" d="M22.048 4.128c-.683 0-1.184-.17-1.504-.512-.299-.341-.448-.79-.448-1.344v-.416c0-.555.15-1.003.448-1.344.32-.341.821-.512 1.504-.512s1.173.17 1.472.512c.32.341.48.79.48 1.344v.416c0 .555-.16 1.003-.48 1.344-.299.341-.79.512-1.472.512Zm-7.904 0c-.683 0-1.184-.17-1.504-.512-.299-.341-.448-.79-.448-1.344v-.416c0-.555.15-1.003.448-1.344.32-.341.82-.512 1.504-.512s1.173.17 1.472.512c.32.341.48.79.48 1.344v.416c0 .555-.16 1.003-.48 1.344-.299.341-.79.512-1.472.512Zm-7.904 0c-.683 0-1.184-.17-1.504-.512-.299-.341-.448-.79-.448-1.344v-.416c0-.555.15-1.003.448-1.344C5.056.171 5.557 0 6.24 0s1.173.17 1.472.512c.32.341.48.79.48 1.344v.416c0 .555-.16 1.003-.48 1.344-.299.341-.79.512-1.472.512Z"/></svg>
              </button>
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default Indicators;
